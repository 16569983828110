<template>
    <div id="hcompanyeva">
        <div class="hcompanyeva_in">
            <div class="company_top">
                <ul>
                    <li @click="tohighCompanytest()">高企评测</li>
                </ul>
            </div>
            <div class="company_middle">
                <!-- <router-view></router-view> -->
                <div class="cmp_mid_left">
                    <div class="cmp_mid_title">基本信息</div>
                    <div class="ocr" >
                        <el-upload
                            class="upload-demo"
                            :action="apiBaseUrl+'/API/UploadFiles'"
                            :on-success="uploadfile"
                            :data="imguploaddata"
                            :before-upload="BeforeUploadYYZZ"
                            :on-remove="RemoveYYZZ"
                            multiple
                            :limit="1"
                            :on-exceed="ExceedYYZZ"
                            :file-list="fileList"
                            >
                            <!-- :show-file-list="false" -->
                            <el-button size="small" type="primary" v-show="isShowUploadBLBtn">识别营业执照</el-button>
                            <!-- <template #tip>
                                <div class="el-upload__tip">只能上传 jpg/png 文件，且不超过 500kb</div>
                            </template> -->
                        </el-upload>
                    </div>
                    <div class="formbox" v-loading="isLoading" element-loading-text="识别中">
                        <el-form 
                        label-position="left"
                        :model="CompanyInfo"
                        ref="CompanyInfo"
                        status-icon
                        :rules="rules"
                        label-width="120px"
                        class="demo-ruleForm"
                        >
                            <el-form-item  label="公司名称"  prop="CompanyName">
                                <el-select v-model="CompanyInfo.CompanyName" 
                                filterable 
                                remote
                                :remote-method="toSearchCompNameList"
                                placeholder="公司名称"
                                :loading="cn_loading"
                                v-el-select-loadmore="loadmore"
                                >
                                <el-option
                                    v-for="(item,index) in CompanyNameList"
                                    :key="index"
                                    :label="item.label"
                                    :value="item.value">
                                </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="纳税人识别号" prop="TaxpayerIdentityNumber">
                                <el-input
                                type="text"
                                v-model="CompanyInfo.TaxpayerIdentityNumber"
                                autocomplete="off"
                                placeholder="即统一社会信用代码"
                                v-on:input="changeTINum()"
                                ></el-input>
                            </el-form-item>
                            <el-form-item label="法人姓名" prop="ArtificialPersonName">
                                <el-input
                                type="text"
                                v-model="CompanyInfo.ArtificialPersonName"
                                placeholder="法人姓名"
                                ></el-input>
                            </el-form-item>
                            <el-form-item label="注册地址" prop="RegAddress">
                                <el-input
                                type="text"
                                v-model="CompanyInfo.RegAddress"
                                placeholder="注册地址"
                                ></el-input>
                            </el-form-item>
                            <el-form-item label="注册日期" prop="CreateDate">
                                <el-date-picker
                                type="date"
                                value-format="yyyy-MM-dd"
                                :picker-options="pickerOptions"
                                placeholder="选择日期"
                                v-model="CompanyInfo.CreateDate"
                                style="width: 100%"
                                ></el-date-picker>
                            </el-form-item>
                            <el-form-item label="经营范围" prop="BusinessScope">
                                <el-input
                                type="text"
                                v-model="CompanyInfo.BusinessScope"
                                placeholder="经营范围"
                                ></el-input>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
                <div class="cmp_mid_right">
                    <div class="cmp_mid_title">知识产权</div>
                    <div class="IntelProList" v-show="CompanyInfo.IntellectualProperty.length>0">
                        <div class="ZSCQListTitle">
                            <div class="ZSCQList_year">年份</div>
                            <div class="ZSCQList_item">项目类型</div>
                            <div class="ZSCQList_number">数量</div>
                            <div class="ZSCQList_operation">操作</div>
                        </div>
                        <div v-for="(item,index) in CompanyInfo.IntellectualProperty" :key="index" class="ZSCQList" >
                            <div class="ZSCQList_year">{{item.year}}</div>
                            <div class="ZSCQList_item">{{item.item}}</div>
                            <div class="ZSCQList_number">{{item.number}}</div>
                            <div class="ZSCQList_operation">
                                <i v-show="!item.isEdit">-</i>
                                <i class="el-icon-delete" @click="del_the_ZSCQ(index)" v-show="item.isEdit"></i> 
                            </div>
                            <!-- <div class="ZSCQList_closeBtn" @click="" v-show="item.isEdit">
                                <div></div>
                            </div> -->
                        </div>
                    </div>
                    <div class="AddBtnInterPro" v-show="CompanyInfo.IntellectualProperty.length>0">
                        <el-button size='medium' @click="ZSCQaddDialog = true">补充</el-button>
                    </div>
                    <div class="nodata" v-show="CompanyInfo.IntellectualProperty.length<=0">
                        <div class="emptyimg">
                            <img src="~/assets/images/empty.png" alt="找不到资源">
                        </div>
                        <div class="emptydescrible">暂无产权证书信息</div>
                        <div class="addbtn">
                            <el-button small @click="ZSCQaddDialog = true">补充</el-button>
                        </div>
                    </div>

                    <el-dialog
                            title="补充知识产权"
                            :visible.sync="ZSCQaddDialog"
                            width="75%"
                            center>
                            <span>
                                <div class="dialogContent">
                                    <div class="dialogtitle">年份</div>
                                    <div class="year">
                                        <div v-for="(item,index) in yearList" :key="index" :class="item == CreateObj.year ? 'activeYear':'normalYear'" @click="chooseYear(item)" >{{item}}</div>
                                    </div>
                                    <div class="dialogtitle">项目类型</div>
                                    <div class="item">
                                        <div 
                                        v-for="(item,index) in serviceitemList" 
                                        :key="index" 
                                        :class="item.ServiceItemName == CreateObj.item ? 'activeItem':'normalItem'"
                                        @click="chooseItem(item.ServiceItemName,item.FLnkID)">{{item.ServiceItemName}}</div>
                                    </div>
                                    <div class="dialogtitle">数量</div>
                                    <div class="count">
                                        <el-input-number v-model="CreateObj.number" @change="changeNumber" :min="1" :max="99" small></el-input-number>
                                    </div>
                                </div>
                            </span>
                            <span slot="footer" class="dialog-footer">
                                <el-button @click="ZSCQaddDialog = false">取 消</el-button>
                                <el-button type="primary" @click="ConfirmAdd">确 定</el-button>
                            </span>
                    </el-dialog>


                </div>
            </div>
            <div class="company_bottom">
                <el-button type="primary" @click="StartAssess('CompanyInfo')">开始评估</el-button>
            </div>
            <footerPage></footerPage>
        </div>
    </div>
</template>

<script>
import footerPage from "@/components/common/footer/footerPage.vue";
export default {
    name:'highCompanyEvaluation',
    directives:{
      'el-select-loadmore':{
        bind(el,binding){
          const SELECTWRAP_DOM = el.querySelector('.el-select-dropdown .el-select-dropdown__wrap')
          SELECTWRAP_DOM.addEventListener('scroll', function () {
            const condition = this.scrollHeight - this.scrollTop <= this.clientHeight;
            if(condition){
                binding.value()
            }
          }) 
        }
      }
    },
    components:{
        footerPage,
    },
    data(){
        const validataTaxpayerIdentityNumber = (rule,value,callback) => {
        const TaxpayerIdentityNumberRule = /^([0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}|[1-9]\d{14})$/;//15/18位
        if(!TaxpayerIdentityNumberRule.test(this.CompanyInfo.TaxpayerIdentityNumber)){
            callback(new Error("请输入合法税号"))
        }
        else{
            callback() 
        }
        }
        return{
            ZSCQaddDialog:false,//控制添加知识产权弹出框
            CompanyInfo:{
                TaxpayerIdentityNumber:'',//税号
                CompanyName:'',           //公司名字
                ArtificialPersonName:'',  //法人
                CreateDate:'',            //成立日期
                RegAddress:'',         //注册地址
                BusinessScope:'',         //经营范围
                IntellectualProperty:[],  //知识产权 
            },                              //企业评估所需信息
            YYZZimgUrl:'',
            //年份列表
            yearList:[new Date().getFullYear()-2,new Date().getFullYear()-1,new Date().getFullYear()],
            serviceitemList:[],           //服务列表
            CreateObj:{year:'',item:'',number:1,isEdit:true},
            rules:{
                TaxpayerIdentityNumber: [
                    {
                        required: true,
                        message: "税号/统一社会信用代码 不可为空",
                        trigger: "blur",
                    },
                    {
                        required: true,
                        validator: validataTaxpayerIdentityNumber,
                        trigger: 'blur'
                    }
                ],
                CompanyName:[
                    { required: true, message: "企业名称不可为空", trigger: "change" },
                    {
                        min: 3,
                        max: 30,
                        message: "企业名称长度在3-20个字符间",
                        trigger: "change",
                    }
                ],
                ArtificialPersonName:[
                    { required: true, message: "法人姓名不可为空", trigger: "blur" }
                ],
                CreateDate:[
                    { required: true, message: "请选择日期", trigger: "change" }
                ],
                RegAddress:[
                    { required: true, message: "注册地址不可为空", trigger:'blur'}
                ],
                BusinessScope:[
                    { required: true, message:  "经营范围不可为空", trigger:'blur'}
                ],
            },                       //规则
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now();
                },
            },                      //日历组件禁选未来日期
            apiBaseUrl:window.sessionStorage.getItem('apiBaseUrl'), //请求前缀
            fileList:[],//文件
            imguploaddata: {
                Version: "",
                FileTypeID: 8,
                ParamsString: "",
                JZToken: "",
                UserGuid: "",
            }, //上传图片需要的参数
            isLoading:false,//是否显示识别中
            isShowUploadBLBtn:true,//是否展示可上传营业执照按钮

            cn_loading:false,//公司名称下拉框加载
            CompanyNameList:[],//公司列表
            
        }
    },
    computed:{
        userinfo(){
            return this.$store.state.userinfo
        },
        //图片前缀
        imgUrlFront() {
            return window.sessionStorage.getItem("apiImgUrl");
        },
    },
    created(){
        this.imguploaddata.JZToken = this.userinfo.TokenCode
        this.imguploaddata.UserGuid = this.userinfo.UserGuid
        this.CreateObj.year = this.yearList[0]
        this.$axios('/API/Get_ServiceItem',{
            Version:'',
            Status:3,
            ServiceTypeGuid:'2878AF2F-6A9D-4C58-A60A-8B6FDCFFC23D',
            JZToken: '',
            UserGuid: '',
        }).then( res => {
            this.serviceitemList = res.data
            this.CreateObj.item = res.data[0].ServiceItemName
            this.CreateObj.flnkid = res.data[0].FLnkID
        })
    },
    methods:{
        //改年份
        chooseYear(item){
            this.CreateObj.year = item
        },
        //改项目类型
        chooseItem(val,flnkid){
            this.CreateObj.item = val
            this.CreateObj.flnkid = flnkid
        },
        //改办理数量
        changeNumber(e){
            if(e>=0 && e<= 99){
                this.CreateObj.number = e
            }else{
                this.CreateObj.number = 1
            }
        },
        //确认补充知识产权
        ConfirmAdd(){
            this.ZSCQaddDialog = false
            let flag = true  
            if(this.CompanyInfo.IntellectualProperty.length>0){
                this.CompanyInfo.IntellectualProperty.forEach( (item,index) => {
                    if(item.year == this.CreateObj.year && item.item == this.CreateObj.item){
                        this.CompanyInfo.IntellectualProperty[index] = this.CreateObj
                        flag = false
                    }
                })
                if(flag){
                  this.CompanyInfo.IntellectualProperty.push(this.CreateObj)  
                }
            }else{
                this.CompanyInfo.IntellectualProperty.push(this.CreateObj)
            }
           

           
            this.CreateObj = {year:this.yearList[0],item:this.serviceitemList[0].ServiceItemName,number:1,isEdit:true}
        },
        //删除选中的知识产权
        del_the_ZSCQ(index){
            this.CompanyInfo.IntellectualProperty.splice(index,1)
        },
        //开始评估
        StartAssess(formName){
            // console.log(formName)
            if(this.userinfo != undefined){
              let arrString = ''
              let temparr = []
              let ZSCQNum = 0
                this.CompanyInfo.IntellectualProperty.forEach( item => {
                    let itemString = item.year+'+'+item.flnkid+'+'+item.number //中间更改拼接为flnkid
                    temparr.push(itemString)
                    ZSCQNum += item.number

                })
                arrString = temparr.join(',')
                //验证内容
                this.$refs[formName].validate((valid) => {
                if (valid) {
                    //数据验证成功---跳转评估页面
                    this.$router.push({
                        name:'highCEResult',
                        query:{
                            CompanyInfo:JSON.stringify(this.CompanyInfo),
                            YYZZimgUrl: this.YYZZimgUrl,
                            arrString: arrString,
                            ZSCQNum: ZSCQNum 
                        }
                    })
                } else {
                    // console.log("error submit!!");
                     this.$message({
                        message:'确保企业基本信息的完整性,以得到评估结果的准确性',
                        type:'error'
                    })
                    return false;
                }
            });
            }else{
                this.$confirm('未登录或登录状态失效','提示',{
                    confirmButtonText:'去登陆',
                    cancelButtonText:'过户再去',
                    type:'warning'
                })
                .then(() => {
                    this.$router.push('/login')
                })
                .catch(() => {})
            }
        },
        //统一社会信用代码识别企业信息
        changeTINum(){
            if(this.CompanyInfo.TaxpayerIdentityNumber.length >= 15){
                //调用通过纳税人识别号获取企业信息
            }
        },
        BeforeUploadYYZZ(){
            this.isLoading = true
        },
        //移除文件的钩子
        RemoveYYZZ(){
            this.$refs['CompanyInfo'].resetFields();
            this.isShowUploadBLBtn = true
        },
        //上传图片超出的提示方法
        ExceedYYZZ(){
            this.$message({
                message: '文件最大上传个数（1个）',
                type:'warning'
            })
        },
        //文件上传成功钩子
        uploadfile(res){
            if(res.status == 201){
                this.$confirm('未登录或登录状态失效', '提示', {
                    confirmButtonText: '去登录',
                    cancelButtonText: '过会再去',
                    type: 'warning'
                }).then(() => {
                    this.$router.push('/login')
                }).catch(() => {
                });
                this.isLoading = false
            }else if(res.status == 200){
                this.$refs['CompanyInfo'].resetFields();
                this.isShowUploadBLBtn = false
                //  this.$refs[formName].validate.resetForm()
                let imgurl = this.imgUrlFront + res.data.split('|')[1]
        
                this.$axios('/Recognition/BDRecognition',{
                    Version:'',
                    ImgUrl:imgurl,
                    JZToken: this.userinfo.TokenCode,
                    UserGuid: this.userinfo.UserGuid
                }).then( res => {
                    this.isLoading = false
                    if(res.status == 200){
                        this.YYZZimgUrl = imgurl
                        this.CompanyInfo.TaxpayerIdentityNumber = res.data.TaxpayerIdentityNumber //税号
                        this.CompanyInfo.CompanyName = res.data.CompanyName  //公司名称
                        this.CompanyInfo.ArtificialPersonName = res.data.ArtificialPersonName //法人
                        let year = res.data.RegisteredDate.split('年')[0]
                        let month = res.data.RegisteredDate.split('年')[1].split('月')[0]
                        let day = res.data.RegisteredDate.split('年')[1].split('月')[1].split('日')[0]
                        this.CompanyInfo.CreateDate = year+'-'+month+'-'+day   //日期
                        this.CompanyInfo.BusinessScope = res.data.BusinessScope //经营范围
                        this.CompanyInfo.RegAddress = res.data.AddressName      //注册地址
                    }
                    else if(res.status){
                        this.$message({
                            message:'系统异常请联系客服',
                            type:'error'
                        })
                    }
                    else{
                        this.$message({
                            message:'识别失败，请刷新页面重试',
                            type:'error'
                        })
                    }
                

                })

            }else{
                this.$message({
                    message:res.msg,
                    type:'error'
                })
            }
        },
        toSearchCompNameList(query){
            this.CompanyInfo.CompanyName = query
            this.cn_loading = true
            let createObj = {lable:'1',value:'江苏省数字矩阵科技有限公司'}
            setTimeout( () => {
                this.cn_loading = false
                this.CompanyNameList=[ 
                    {lable:'1',value:'江苏省数字矩阵科技有限公司'}, 
                    {lable:'2',value:'江苏省数字矩阵科技有限公司123'},
                    {lable:'3',value:'江苏省数字矩阵科技有限公司321'},
                    {lable:'4',value:'江苏省数字矩阵科技有限公司旁敲'},
                    {lable:'5',value:'江苏省数字矩阵科技有限公司侧击'},
                    {lable:'6',value:'江苏省数字矩阵科技有限公司Penta'},
                    {lable:'6',value:'江苏省数字矩阵科技有限公司Penta'},
                    {lable:'6',value:'江苏省数字矩阵科技有限公司Penta'},
                    {lable:'6',value:'江苏省数字矩阵科技有限公司Penta'},
                ]

            },1500)
        },
        //加载更多公司列表
        loadmore(){
            // let extraList =[ 
            //     {lable:'9',value:'九十九个我'}, 
            //     {lable:'10',value:'从三味园到百草书屋'}
            // ]
            // this.CompanyNameList = this.CompanyNameList.concat(extraList)
        },
    }

}
</script>

<style lang="scss" scoped>
    @import './highCompanyEvaluation.scss';
</style>